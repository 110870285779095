import React from 'react';
import { Image } from 'react-konva';
import useImage from 'use-image';

interface Props {
	pictureUrl: string;
	width?: number;
	height?: number;
}

export const Picture = (props: Props) => {
	const [image] = useImage(props.pictureUrl);
	return <Image image={image} width={props.width} height={props.height} />;
};
