import React from 'react';
import styles from './styles.module.css';
import { Stage, Layer, Rect } from 'react-konva';
import { Picture } from '../Picture';
import { CirclesLayer } from '../CirclesLayer';
import { Coordinates, convertCoordinatesToOriginal } from 'src/helpers/CanvasHelpers';
import { KonvaEventObject } from 'konva/types/Node';
import { LayerClick } from 'src/model/LayerClick';

interface State {}
interface Props {
	currCoordinates: Coordinates[];
	pictureUrl: string;
	radius: number;
	handleCanvasClick: (e: any) => void;
}

export class ModalView extends React.Component<Props, State> {
	private zoomFactor: number;

	constructor(props: Props) {
		super(props);
		this.zoomFactor = 1.5;
	}

	render() {
		return (
			<div className={styles.questionsContainer}>
				<div className={styles.canvasContainer}>
					<div className={styles.pictureContainer}>
						<div className={styles.pictureCanvasContainer}>
							<Stage
								className={styles.answerCanvases}
								width={1000 * this.zoomFactor}
								height={562 * this.zoomFactor}
							>
								<Layer>
									<Picture
										pictureUrl={this.props.pictureUrl}
										width={1000 * this.zoomFactor}
										height={562 * this.zoomFactor}
									/>
								</Layer>
								<Layer
									onClick={(e: KonvaEventObject<LayerClick>) => {
										const coord = { x: e.evt.layerX, y: e.evt.layerY };
										this.props.handleCanvasClick(
											convertCoordinatesToOriginal(coord, this.zoomFactor)
										);
									}}
								>
									{/* "Dummy" rectangle to make the Layer fill the canvas so it can be clickable */}
									<Rect width={1000 * this.zoomFactor} height={562 * this.zoomFactor} />
									{this.props.currCoordinates && (
										<CirclesLayer
											circleCoords={this.props.currCoordinates.map((coord: Coordinates) => {
												return { x: coord.x * this.zoomFactor, y: coord.y * this.zoomFactor };
											})}
											radius={this.props.radius}
										/>
									)}
								</Layer>
							</Stage>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
